.main {
  display: none;
}
@media screen and (max-width: 1000px) {
  .main {
    display: block;
    width: 100%;
    height: 100px;
    background: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    position: relative;
  }
  .container {
    width: 95%;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    width: auto;
    height: 100%;
    display: flex;
  }
  .right {
    width: 55%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .logoContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    width: 170px;
    height: 24px;
  }
  .logoContainer h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    text-transform: uppercase;

    color: #273878;
  }
  .links {
    margin-left: 80px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .links > a,
  .links > h4 {
    text-decoration: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;

    color: #464c62;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .links > h4 {
    min-width: 220px;
  }
  .links a:nth-child(1) {
    margin-right: 50px;
  }
  .links h4 {
    position: relative;
  }
  .links h4:hover .invisible {
    display: block;
  }
  .invisible {
    display: none;
    top: 100%;
    left: 0px;
    position: absolute;
    background-color: #fff;
    width: 150%;
    text-align: left;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .invisible a {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding-left: 5px;
    text-align: left;
    padding: 10px;
    transition: all 0.3s ease;
    width: 100%;
    color: #464c62;
  }
  .invisible a:hover {
    background: #296acb;
    color: #fff;
    transition: all 0.3s ease;
  }
  .numbers {
    width: 140px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 100px;
  }
  .svgContainer {
    height: 30px;
  }
  .numbers svg {
    fill: #434343;
    width: 12px;
    height: 12px;
  }
  .numbers h5 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    color: #434343;
  }

  .search {
    width: 20px;
    height: 20px;
    fill: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .langSearch {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .language {
    display: flex;
    margin-right: 40px;
    position: relative;
    height: 100%;
    width: 70px;
  }
  .language > h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #464c62;
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 3.5px;
  }
  .down {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .down svg {
    font-size: 15px;
    cursor: pointer;
  }
  .language:hover .langs {
    display: block;
  }
  .langs {
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0px;
    display: none;
    width: 100%;
  }
  .langs h3 {
    padding: 10px 5px;
    cursor: pointer;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #464c62;
  }
  .langs h3:hover {
    background: #296acb;
    color: #fff;
    transition: all 0.3s ease;
  }
  .navOpen {
    display: flex;
    width: 100%;
    top: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .navOpen a {
    padding: 10px 20px;
    width: 100%;
    text-decoration: none;
  }
  .menu {
    position: absolute;
    right: 5%;
    top: 43%;
    width: 20px;
    height: 20px;
  }
  .container {
    width: 75%;
    margin-left: 5%;
  }
}
@media screen and (max-width: 850px) {
  .numbers {
    display: none;
  }
  .right {
    width: auto;
  }
  .search {
    display: none;
  }
  .language {
    margin-right: 0px;
  }
}
