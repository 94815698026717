.main {
  width: 295px;
  margin-left: 10%;
  height: 100vh;
  padding-bottom: 100px;
  padding-top: 40px;
}
.main h1 {
  min-height: 50px;
  width: 233px;
  max-width: 450px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #464c62;
  text-decoration: none;
  padding-left: 27.62px;
  border: solid;
  border-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0 0 0 1.87px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 10%;
}
.main h1.clicked {
  border: solid #296acb;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0 0 0 1.87px;
  font-weight: 600;
  font-size: 15px;
  color: #296acb;
}
@media screen and (max-width: 1100px) {
  .main {
    height: auto;
  }
}
