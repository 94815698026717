.main {
  width: 100%;
  padding: 45px 0px;
}
.container {
  width: 80%;
  margin: auto;
}
.container h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #273878;
  margin-bottom: 30.9px;
}
.container input {
  background: #ffffff;
  border: 1px solid #82858b;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 82.82px;
  outline: none;
  margin-bottom: 30px;
  padding: 0px 32px;
  font-family: SF Pro Text;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Color/Neutral/2 - Grey */

  color: #3f3356;

  border-radius: 3px;
}
.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
}
.buttons button {
  width: 169px;
  height: 41px;
  margin: 0px 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
}
.buttons button:nth-child(1) {
  background: #333333;
  box-shadow: 0px 2px 2px rgba(156, 39, 176, 0.2);
}
.buttons button:nth-child(2) {
  background: #296acb;
  box-shadow: 0px 2px 2px rgba(156, 39, 176, 0.2);
  position: relative;
}
.buttons button svg {
  position: absolute;
  right: 14px;
}
@media screen and (max-width: 800px) {
  .buttons button {
    width: 150px;
  }
  .container input {
    height: 52px;
  }
}
