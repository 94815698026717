.main {
  width: 767px;
  padding-bottom: 50px;
}
.main > h1 {
  margin: 30px 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */

  color: #333333;
}
.tables {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  height: 80px;
  width: 730px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f6f7;
  border: 1px solid #f3f8fb;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 60px;
  cursor: pointer;
  position: relative;
}
.table h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #333333;
}
.svg {
  transform: rotateZ(-90deg);
  transition: 0.6s ease all;
}
.table svg {
  transition: all 0.6s ease;
  width: 25px;
  height: 25px;
}
.table > div {
  display: flex;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0%;
  left: 0%;
  justify-content: space-between;
  padding: 0px 20px;
  border-bottom: 1px solid #333333;
  z-index: -1;
  transition: all 0.7s ease;
}
.table .open {
  top: 100%;
}

.main p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  color: #333;
  padding: 4px 0px;
}
.main span {
  margin-right: 40px;
}
@media screen and (max-width: 1100px) {
  .main {
    width: 100%;
  }
  .table {
    width: 100%;
  }
  .main h1 {
    margin-left: 5%;
  }
  .container {
    width: 90%;
    margin: auto;
  }
}
