.main {
  width: 100%;
  padding: 50px 0px;
  background-color: #fbfbfb;
}
.container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.information {
  width: 900px;
}
.information h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
  /* identical to box height */

  color: #333333;
}
.information h2 {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;

  color: #333333;
}
.save {
  width: 220px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  width: 220px;
  height: 47px;
  display: flex;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.button h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #464c62;

  border-radius: 3px;
  width: 110px;
  text-align: center;
}
.button img {
  width: 25px;
  height: 25px;
}
.button:hover {
  background-color: #296acb;
}
.button:hover > h3 {
  color: #ffffff;
}
.button:hover .language {
  display: block;
}
.language {
  display: none;
  left: 0%;
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.language a {
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #464c62;
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
}
.language a:hover {
  background-color: rgb(242 242 242);
  width: 100%;
  transition: all 0.5s ease;
}
@media screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
  }
  .information {
    width: auto;
  }
  .information h1 {
    font-size: 25px;
  }
  .information h2 {
    font-size: 14px;
  }
  .save {
    width: 100%;
    padding: 20px 0px;
  }
}
