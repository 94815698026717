.main {
  width: 100%;
  padding: 70px 0px;
  background-color: #fdfdfd;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
}
.container {
  width: 80%;
  margin: auto;
  display: flex;
}
.container img {
  margin-right: 12px;
}
.container h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 200;
  font-size: 35px;
  line-height: 48px;
  /* identical to box height */

  color: #333333;
}
.container span {
  font-weight: bold;
}
@media screen and (max-width: 960px) {
  .container h1 {
    font-size: 18px;
  }
  .container {
    flex-direction: column-reverse;
  }
  .container img {
    width: 20px;
    height: 20px;
  }
  .main {
    padding: 50px 0px;
  }
}
