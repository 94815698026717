.main {
  width: 100%;
  margin: 60px 0px;
}
.container {
  width: 80%;
  margin: auto;
}
.container h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;

  color: #333333;
  margin-bottom: 40px;
}
.boxes {
  display: flex;
}
.box {
  width: 235px;
  height: 105px;
  background: #fafafa;
  border: 1px solid #afafaf;
  border-radius: 5px;
  margin: 15px;
  display: flex;
  flex-direction: column;
}
.box:hover {
  box-shadow: 0px 0px 14px 0px #afafaf;
}
.box h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: #273878;
  display: flex;
  align-items: center;
  padding: 10px 0px 5px 20px;
}
.box svg {
  margin-right: 10px;
  fill: red;
  font-size: 15px;
}
.box h4 {
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: #333333;
  padding: 10px 0px 5px 40px;
}
@media screen and (max-width: 1100px) {
  .boxes {
    flex-direction: column;
  }
}
