.main {
  width: 100%;
  padding: 70px 0px;
  background: #333333;
}
.container {
  width: 80%;
  margin: auto;
  display: flex;
}
.left h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
  margin-top: 3px;
  margin-bottom: 10px;
}
.left {
  width: 249px;
  margin-right: 408px;
}
.left h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: rgba(255, 255, 255, 0.72);
}
.middle {
  width: 184px;
  margin-right: 60px;
}
.middle > h3,
.right > h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
  margin-bottom: 15px;
}
.address {
  display: flex;
  width: 100%;
}
.address svg {
  fill: #fff;
  margin-right: 11px;
}
.nums {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: #ffffff;
}
@media screen and (max-width: 1000px) {
  .container {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  .left {
    margin-right: 0px;
    width: auto;
  }
  .left h3,
  .left h4 {
    font-size: 12px;
  }
  .middle {
    width: auto;
    margin: 0px;
  }
}
@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .container > div {
    margin: 30px 0px;
  }
  .main {
    padding: 0px;
  }
}
