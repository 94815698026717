.main {
  width: 100%;
  padding: 50px 0px;
  margin-bottom: 30px;
}
.container {
  width: 80%;
  margin: auto;
}
.container > h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #273878;
  margin-bottom: 40px;
}
.section {
  width: 100%;
  display: flex;
  border-bottom: 2px solid #c4c4c4;
  padding-bottom: 15px;
}
.section > div {
  display: flex;
  align-items: center;
}
.code {
  width: 140px;
}
.name {
  width: 905px;
}
.number {
  width: 120px;
}
.section > div h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: #333333;
  margin-right: 15px;
}
.section > div svg {
  fill: #afafaf;
  cursor: pointer;
}
.items {
  margin-bottom: 78px;
}
.items > div:nth-child(odd) {
  background: #fbfbfb;
}
.item {
  display: flex;
  padding: 14px 0px;
  cursor: pointer;
}
.item a {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}
.item .code h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: #296acb;
  padding-left: 15px;
}
.item h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;

  color: #000000;
}
.items .item:hover {
  background-color: #e0dddd;
}

.items,
.section {
  min-width: 700px;
}
@media screen and (max-width: 960px) {
  .main {
    overflow-x: scroll;
    padding: 10px 20px;
  }
  .container {
    width: 100%;
  }
}
