.main {
  width: 767px;
  padding-bottom: 50px;
}
.main > h1 {
  margin: 30px 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */

  color: #333333;
}
.box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}
.box h3 {
  padding: 14px 0px;
  width: 236px !important;
  margin-right: 64px !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;

  color: #333333;
}
.box h4 {
  width: auto;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #333333;
}
.box a {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;

  color: #296acb;
  width: 340px;
}

@media screen and (max-width: 1100px) {
  .main {
    min-width: 100%;
    overflow-x: scroll;
    width: 100%;
  }
  .main h1 {
    margin-left: 5%;
  }
  .box {
    width: 90%;
    margin: auto;
  }
  .box h3 {
    width: 103px;
  }
}
