.main {
  width: 100%;
  padding: 50px 0px;
  margin-bottom: 30px;
}
.container {
  width: 80%;
  margin: auto;
}
.section {
  width: 100%;
  display: flex;
  border-bottom: 2px solid #c4c4c4;
  padding-bottom: 15px;
}
.section > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section .number h3 {
  width: 60px;
}
.section .docs {
  width: 770px;
}
.section .status {
  width: 200px;
}
.section .save {
  width: 145px;
}
.section > div h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: #333333;
}
.items {
  width: 100%;
  margin-bottom: 78px;
}
.items > div:nth-child(odd) {
  background: #fbfbfb;
}
.item {
  display: flex;
  padding: 14px 0px;
  cursor: pointer;
}
.item a {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}
.item .code h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: #296acb;
  padding-left: 15px;
}
.item h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;

  color: #000000;
}
.items .item:hover {
  background-color: #e0dddd;
}
.item .number h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  color: #787878;
  width: 60px;
}
.item .name a {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: #296acb;
  text-decoration: none;
}
.item .name h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;

  color: #000000;
}
.item .name {
  width: 650px;
  margin-right: 30px;
}
.item .status {
  width: 200px;
}
.item .status h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #333333;
}
.item .status .one {
  color: rgb(64, 181, 191);
}
.item .status .two {
  color: rgb(132, 45, 108);
}
.item .status .three {
  color: rgb(191, 64, 64);
}
.button {
  display: flex;
  width: 130px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 2px;
  align-items: center;
  padding: 9px;
  position: relative;
}
.button h4 {
  color: #464c62;
  margin-right: 10px;
}
.button:hover h4 {
  color: #dedede;
}
.button:hover .languages {
  display: block;
}
.languages {
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  background-color: #ffffff;
  width: 100%;
  border-top: 1px solid #787878;
  z-index: 20;
}

.languages a {
  padding: 6px 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #464c62;
  text-align: center;
  cursor: pointer;
  width: 100%;
  display: flex;
}

.languages a:hover {
  background-color: #e0dddd;
}
.languages img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0px 15px;
}
.error {
  width: 100%;
  text-align: center;
  padding: 6px 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #464c62;
  text-align: center;
}
.items,
.section {
  min-width: 750px;
}
@media screen and (max-width: 960px) {
  .main {
    overflow-x: scroll;
    padding: 10px 20px;
  }
  .container {
    width: 100%;
  }
}
