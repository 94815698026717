.main {
  width: 100%;
  padding: 50px 0px;
}
.container {
  width: 80%;
  margin: auto;
}
.container > h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #273878;
  margin-bottom: 40px;
}
.sections {
  display: flex;
  width: 100%;
}
.left {
  width: 746px;
  margin-right: 68px;
}
.item {
  width: 100%;
  padding: 19px 20px;
  margin: 10px 0px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.top > h2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: #333333;
}
.time {
  display: flex;
  align-items: center;
}
.time svg {
  fill: #a2a2a2;
  font-size: 11px;
}
.time p {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  color: #aaaaaa;
  margin: 0px 7px;
}
.time h5 {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;

  color: #5e5e5e;
}
.item > p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* or 120% */

  text-decoration-line: underline;

  color: #296acb;
  margin-bottom: 20px;
}
.item > h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  /* or 100% */

  color: #333333;
}
.right {
  width: 353px;
  height: 311px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.svg {
  width: 35px;
  height: 40px;
  background-size: contain;
  background-position: center;
}
.right h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  color: #333333;
  width: 230px;
  margin-top: 8px;
  margin-bottom: 33px;
}
.right button {
  background: #296acb;
  box-shadow: 0px 5px 10px #5599ff;
  border-radius: 50px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* display: flex;
  align-items: center;
  text-align: center; */

  color: #ffffff;

  border-radius: 50px;
  padding: 8px;
  width: 197px;
  text-align: center;
}
.left button {
  display: none;
}
@media screen and (max-width: 1100px) {
  .sections {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left {
    width: 100%;
    margin-right: 0px;
  }
  .item p {
    font-size: 14px;
  }
  .right button {
    font-size: 10px;
  }
}
