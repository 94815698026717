.main {
  width: 100%;
  height: 210px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  margin-bottom: 90px;
}
.main h1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  color: #ffffff;

  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 600px;
  margin-left: 10%;
}
@media screen and (max-width: 800px) {
  .main h1 {
    font-size: 18px;
  }
  .main {
    margin-bottom: 30px;
  }
}
